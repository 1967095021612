// Contants
import { ADDONS } from '@/constants'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters } from 'vuex'
// Filters
import { formatPrice } from '@/filters'
// Services
import { getWalletTransactions, moveWalletBalanceToPaypal } from '@/services/company'
// Utils
import { get } from 'lodash'

export default {
  name: 'WalletMakeTransaction',
  filters: { formatPrice },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      // Mínimo para cobrar con PayPal
      minPaypal: 10,
      // wallet balance
      walletAmount: 0,
      // Others
      processingRequest: true,
      loadingButton: false
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    ...mapGetters('place', ['placeData']),
    /**
     * El botón de acción está activado
     * @return {boolean}
     */
    enabledButton() {
      return this.walletAmount >= this.minPaypal && this.paypalAccount
    },
    /**
     * Cuenta de PayPal de la compañía
     *
     * @return {number}
     */
    paypalAccount() {
      return get(this.companyAddonsSetupByUser, `${ADDONS.company}.paypalAccount`, null)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Evento lanzado cuando pulsamos sobre el botón de acción
     */
    async handleClickButton() {
      try {
        // Loading
        this.loadingButton = true

        // Obtenemos datos y enviamos petición
        const placeName = get(this.placeAddonsSetupByUser, `${ADDONS.place}.name`, null)

        const result = await moveWalletBalanceToPaypal({
          amount: this.walletAmount,
          id: this.companyData.id,
          paypalAccount: this.paypalAccount,
          placeId: this.placeData.id,
          placeName
        })

        if (!result) {
          throw new Error('Error en petición, póngase en contacto con el proveedor del servicio.')
        }

        // El proceso finalizó correctamente
        this.modifyAppAlert({
          text: 'La petición fue enviada correctamente, en el periodo de 72 horas el dinero estará disponible en su cuenta.',
          visible: true
        })
        // Volvemos a pedir el saldo actual
        this.getEveryNeededData()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.loadingButton = false
      }
    },
    /**
     * Obtenemos todos los datos necesarios para el componente
     */
    async getEveryNeededData() {
      // Loading
      this.processingRequest = true
      try {
        // Obtenemos total del "wallet"
        this.setWalletBalance()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos y establecemos saldo en el wallet
     * y los movimientos de este
     */
    async setWalletBalance() {
      const { walletAmount } = await getWalletTransactions(this.companyData.id)
      // Establecemos balance
      this.walletAmount = walletAmount
    }
  }
}
