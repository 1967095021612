// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
import WalletData from '../WalletData'
import WalletMovements from '../WalletMovements'

export default {
  name: 'WalletByTabs',
  components: {
    VuetifyToolBar,
    VuetifyTabs
  },
  data() {
    return {
      items: [
        {
          label: 'Movimientos',
          component: WalletMovements
        },
        {
          label: 'Configuración',
          component: WalletData
        }
      ]
    }
  }
}
