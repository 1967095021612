// Mixins
import uiMixin from '@/mixins/uiMixin'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import WalletTransactionsItems from '@/components/elements/company/WalletTransactionsItems'
import AlertCommissionsLocked from '@/components/ui/AlertCommissionsLocked.vue'
// Filters
import { formatPrice, formatDate } from '@/filters'
// Vuex
import { mapGetters } from 'vuex'
// Services
import { getWalletTransactions } from '@/services/company'

export default {
  name: 'WalletMovements',
  filters: { formatPrice },
  mixins: [uiMixin],
  components: {
    CardContainer,
    CardContainerElement,
    VuetifyContentLoading,
    VuetifyDataTable,
    WalletTransactionsItems,
    AlertCommissionsLocked
  },
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Concepto',
          value: 'subject'
        },
        {
          text: 'Fecha',
          value: 'date'
        },
        {
          text: 'Tipo',
          value: 'type'
        },
        {
          text: 'Cantidad',
          value: 'amount'
        }
      ],
      // Others
      processingRequest: true,
      // Transacciones
      transactions: [],
      walletAmount: 0
    }
  },
  computed: {
    ...mapGetters('company', ['companyData'])
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Obtenemos todos los datos necesarios para el componente
     */
    async getEveryNeededData() {
      // Loading
      this.processingRequest = true
      try {
        // Obtenemos transacciones
        await this.setWalletTransactions()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos y establecemos saldo en el wallet
     * y los movimientos de este
     */
    async setWalletTransactions() {
      const { walletAmount, data } = await getWalletTransactions(this.companyData.id)

      // Establecemos balance
      this.walletAmount = walletAmount
      // Establecemos movimientos
      this.transactions = data.map((transaction) => {
        return {
          id: transaction.id,
          subject: transaction.observations || '-',
          date: formatDate(transaction.createTimestamp, 'DD/MM/YYYY'),
          type: transaction.transactionType,
          amount: formatPrice(transaction.balance, 'EUR')
        }
      })
    }
  }
}
