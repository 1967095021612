// Components
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import WalletPayPalAccount from '@/components/elements/company/WalletPayPalAccount'
import WalletMakeTransaction from '@/components/elements/company/WalletMakeTransaction'

export default {
  name: 'WalletData',
  components: {
    CardContainer,
    CardContainerElement,
    WalletPayPalAccount,
    WalletMakeTransaction
  }
}
